import React, { Component } from 'react';
import {Collection, CollectionItem, Icon} from "react-materialize";
import {SidebarEditor} from "./SidebarEditor";
import {LABEL_DID_INIT} from "../routes/App";
import CanvasButtons from "./CanvasButtons";

export class LabelBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorType: '',
      isSidebarOpen: false
    };
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleSidebarClose = this.handleSidebarClose.bind(this);
    this.handleSidebarOpen = this.handleSidebarOpen.bind(this);
  }

  handleItemClick(type) {
    if (!LABEL_DID_INIT) return;

    if (type === 'text' && this.props.joyrideRunning) {
      setTimeout(() => {
        this.props.addSteps([
          {
            title: 'Crea un nuovo oggetto',
            text: "Questo pulsante permette di aggiungere un nuovo testo sull'etichetta.",
            selector: '.add-button',
            position: 'right',
            allowClicksThruHole: true,
            style: {
              back: {
                display: 'none'
              },
              button: {
                display: 'none'
              }
            }
          }
        ], () => this.props.joyride.next());
      }, 50);
    }

    if (this.state.editorType !== type) {
      this.handleSidebarOpen(type);
    } else {
      this.handleSidebarClose();
    }
  }

  handleSidebarClose() {
    this.setState({
      editorType: '',
      isSidebarOpen: false
    });
  }

  handleSidebarOpen(type) {
    this.setState({
      editorType: type,
      isSidebarOpen: true
    });
  }

  render() {
    let s = this.state;
    return (
      <div>
        <div className="no-print palette-sidebar" id="builder-container">
          <Collection>
            <CollectionItem className={"waves-effect background-containerdefault palette " + ((s.editorType === 'text') ? "sidebar-selected" : "")}
                            onClick={() => this.handleItemClick('text')} data-tip="Testo" data-for="global-tooltip" href="">
              <Icon className="palette-icon">text_fields</Icon>
            </CollectionItem>
            <CollectionItem className={"waves-effect background-containerdefault palette " + ((s.editorType === 'image') ? "sidebar-selected" : "")}
                            onClick={() => this.handleItemClick('image')} data-tip="Immagine" data-for="global-tooltip" href="">
              <Icon className="palette-icon">image</Icon>
            </CollectionItem>
            <CollectionItem className={"waves-effect background-containerdefault palette " + ((s.editorType === 'shape') ? "sidebar-selected" : "")}
                            onClick={() => this.handleItemClick('shape')} data-tip="Forma e Icona" data-for="global-tooltip" href="">
              <Icon className="palette-icon">widgets</Icon>
            </CollectionItem>
            <CollectionItem className={"waves-effect background-containerdefault palette " + ((s.editorType === 'code') ? "sidebar-selected" : "")}
                            onClick={() => this.handleItemClick('code')} data-tip="Codice a barre e QR" data-for="global-tooltip" href="">
              <Icon className="palette-icon">code</Icon>
            </CollectionItem>
            {/*<CollectionItem className={"waves-effect background-containerdefault palette " + ((s.editorType === 'brush') ? "sidebar-selected" : "")}
                            onClick={() => this.handleItemClick('brush')} data-tip="Disegno libero" data-for="global-tooltip" href="">
              <Icon className="palette-icon">brush</Icon>
            </CollectionItem>*/}
            <CollectionItem className={"waves-effect background-containerdefault palette " + (s.editorType === 'bg-color' ? "sidebar-selected" : "")} data-tip="Colore Sfondo" data-for="global-tooltip" href=""
                            onClick={() => this.handleItemClick('bg-color')}>
              <Icon className="palette-icon">format_color_fill</Icon>
            </CollectionItem>
            <CollectionItem className={"waves-effect background-containerdefault palette " + (s.editorType === 'union-upload' ? "sidebar-selected" : "")} data-tip="Stampa Unione" data-for="global-tooltip" href=""
                              onClick={() => this.handleItemClick('union-upload')}>
              <Icon className="palette-icon">file_upload</Icon>
            </CollectionItem>
            <CollectionItem className="waves-effect background-containerdefault palette" data-tip="Annulla" data-for="global-tooltip" href=""
                            onClick={() => {
                              if (!LABEL_DID_INIT) return;
                              this.props.canvasManager.fabricCanvas.undo();
                            }}>
              <Icon className="palette-icon">undo</Icon>
            </CollectionItem>
            <CollectionItem className="waves-effect background-containerdefault palette" data-tip="Ripristina" data-for="global-tooltip" href=""
                            onClick={() => {
                              if (!LABEL_DID_INIT) return;
                              this.props.canvasManager.fabricCanvas.redo();
                            }}>
              <Icon className="palette-icon">redo</Icon>
            </CollectionItem>
          </Collection>
        </div>
        <CanvasButtons/>
        <SidebarEditor joyride={this.props.joyride}
                       joyrideRunning={this.props.joyrideRunning}
                       addSteps={this.props.addSteps}
                       open={s.isSidebarOpen}
                       type={s.editorType}
                       onSidebarClose={this.handleSidebarClose}
                       onSidebarOpen={this.handleSidebarOpen}/>
      </div>
    );
  }
}