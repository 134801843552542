import React, { Component } from 'react';
import {api, Status} from "../api";
import {Button, Col, Icon, Preloader, Row} from "react-materialize";
import ReactTooltip from "react-tooltip";
import {toast} from "react-toastify";
import {Kitchen} from "./FabricCanvas"

export let DocumentParsedInfo = [];
let DocumentParsedKeys = [];

class PlaceholderText extends Component {
  constructor(props) {
    super(props);
    this.createText = this.createText.bind(this);
  }

  createText() {
    return Kitchen.Text().add(
      '{' + this.props.text + '}', 'Arial', 20, '#000', 'left', true // No switching
    );
  }

  render() {
    return (
      <Row>
        <Col s={2}>
          <Button data-tip={this.props.tip || "Aggiungi Segnaposto"} data-for="tooltip-sidebar" className='defaultcolor editor-button add-button' waves='light' onClick={this.createText}>
            <Icon className="editor-icon">add</Icon>
          </Button>
        </Col>
        <Col s={10}>
          <p className="placeholder-text">{this.props.label}</p>
        </Col>
      </Row>
    )
  }
}

export class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileName: '',
      status: 0,
      error: '',
      uploading: false,
      parsedKeys: []
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFileSelect = this.onFileSelect.bind(this);
    this.renderCloseButton = this.renderCloseButton.bind(this);
  }

  onFormSubmit(e) {
    if (!this.state.file) return;
    e.preventDefault();
    this.setState({
      uploading: true
    });
    api.upload_document(localStorage.getItem('loginToken'), this.state.file).then(res => {
      if (res.status) {
        if (res.status !== Status.OK) {
          if (res.status === Status.FORBIDDEN) localStorage.removeItem('loginToken');
          this.setState({
            uploading: false,
            status: res.status,
            error: res.error,
            parsedKeys: []
          });
          toast.error(
            <div>
              <h3>UPLOAD FILE FALLITO! ({this.state.status})</h3>
              <p>{this.state.error}</p>
            </div>
          )
        } else {
          DocumentParsedInfo = res.parsed;
          DocumentParsedKeys = res.parsed.length > 0 ? Object.getOwnPropertyNames(res.parsed[0]) : [];
          this.setState({
            uploading: false,
            status: res.status,
            parsedKeys: res.parsed.length > 0 ? Object.getOwnPropertyNames(res.parsed[0]) : []
          });
          toast.success(
            <div>
              <b>UPLOAD FILE RIUSCITO!</b>
              <p>Sono stati caricati {res.parsed.length} elementi</p>
            </div>
          )
        }
      }
    });
  }

  onFileSelect(e) {
    if (e.target.files.length > 0) {
      this.setState({
        file: e.target.files[0],
        fileName: e.target.files[0].name
      });
    }
  }

  renderCloseButton() {
    return (
      <Button className="actioncolor editor-button closeicon-sidebar"
              onClick={() => this.props.closeSidebar()}><Icon>close</Icon></Button>
    )
  }

  render() {
    return (
      <div className={this.props.className + (this.props.currentEditorType === this.props.type ? " show" : " hide")}>
        <p className="header-sidebar"><Icon className="icon-sidebar">file_upload</Icon> STAMPA UNIONE</p>
        {this.renderCloseButton()}
        <div className="bordable-default paddingbottom20">
          <form onSubmit={this.onFormSubmit}>
            <input id="inputxls" className="input-xls" type="file" name="upload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={this.onFileSelect}/>
            <label data-tip="Scegli un file Excel da cui caricare i dati" data-for="tooltip-sidebar" for="inputxls"><Icon className="valignbottom">file_upload</Icon> {this.state.fileName === '' ? 'Scegli file' : this.state.fileName}</label>
            <Button className="actioncolor" disabled={!this.state.file} type="submit" data-tip="Carica file" data-for="tooltip-sidebar">Carica</Button>
          </form>
        </div>
        {
          this.state.uploading &&
            <Preloader color="green" active={this.state.uploading}/>
        }
        {
          DocumentParsedKeys.length > 0 &&
            <div className="bordable-default paddingbottom20 margintop28">
              {
                DocumentParsedKeys.map(pk => {
                  return (
                    <PlaceholderText label={pk} text={pk}/>
                  );
                })
              }
            </div>
        }
        <p className="header-sidebar"><Icon className="icon-sidebar">format_list_numbered</Icon> SERIE AUTOMATICHE</p>
        <div className="bordable-default margintop20">
          <PlaceholderText label={"Serie di Numeri"} tip={"Aggiungi una serie di numeri. Sulla stampa saranno sostituiti con 1, 2, 3 etc..."} text={"1,2,3..."}/>
          <PlaceholderText label={"Serie di Lettere"} tip={"Aggiungi segnaposto per una serie di lettere. Sulla stampa saranno sostituiti con A, B, C, etc..."} text={"A,B,C..."}/>
        </div>
        <p className="header-sidebar" data-tip="La stampa unione ti permette di caricare un file Excel sul sistema e stampare tutti i valori in sequenza sulle etichette. Utile per liste di nominativi, città, etc..." data-for="tooltip-sidebar"><Icon className="icon-sidebar">help_outline</Icon> Come funziona?</p>
        <ReactTooltip id="tooltip-sidebar"/>
      </div>
    );
  }
}