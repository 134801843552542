import React, { Component } from 'react';
import {api, Status} from "../api";
import {Button, Col, Icon, Input, Row} from "react-materialize";
import {Redirect} from 'react-router-dom';
import {toast} from "react-toastify";
import KeyHandler, { KEYUP } from 'react-key-handler';
import AppWrapper from "../components/AppWrapper";
import {config} from "../config/config";

export let LoginToken = null;
export function saveLoginToken() {
  localStorage.setItem('loginToken', LoginToken);
}
export function grabLoginToken() {
  LoginToken = localStorage.getItem('loginToken');
}
export function getLoginToken() {
  return localStorage.getItem('loginToken');
}
export function removeLoginToken() {
  localStorage.removeItem('loginToken');
}

export let current_username = "";
export let needs_tutorial = false;
export const setTutorial = function(active) {
  needs_tutorial = active;
};

export class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      token: '',
      badInput: false,
      loggedIn: false,
      status: 0,
      wantsRegister: false,
      wantsReset: false
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
  }

  static popSuccess(message) {
    toast.success(
      <div>
        {message}
      </div>, {
        autoClose: 5000
      }
    )
  }
  static popError(error) {
    toast.error(
      <div>
        {error}
      </div>, {
        autoClose: 5000
      }
    )
  }

  componentDidMount() {
    if (!config.enable_login) {
      this.setState({
        badInput: false,
        loggedIn: true
      });
    }

    grabLoginToken();
    if (LoginToken !== null) {
      this.setState({
        token: LoginToken,
        badInput: false,
        loggedIn: true
      });
    }
  }

  handleLogin() {
    api.login(this.state.username, this.state.password)
    .then((response) => {
      if (response.status) {
        if (response.status === Status.OK) {
          LoginToken = response.token;
          saveLoginToken();
          this.setState({
            token: response.token,
            loggedIn: true
          });
          current_username = this.state.username;
          needs_tutorial = response.tutorial;
          localStorage.setItem('username', current_username);
        } else {
          if (response.status === Status.UNAUTHORIZED) {
            LoginToken = null;
            this.setState({
              token: null,
              badInput: true
            });
            Login.popError(
              <div>
                <b>LOGIN FALLITO!</b><br/>
                <p>Le credenziali sono errate, l'account non è stato attivato o non esiste.</p>
              </div>
            );
          } else if (response.status === Status.INTERNAL_SERVER_ERROR) {
            LoginToken = null;
            this.setState({
              token: null,
              internalServerError: true
            });
            Login.popError(
              <div>
                <b>LOGIN FALLITO!</b><br/>
                <p>Il server è offline o in manutenzione.</p>
              </div>
            );
          } else {
            LoginToken = null;
            this.setState({
              token: null,
              status: response.status
            });
            Login.popError(
              <div>
                <b>LOGIN FALLITO!</b><br/>
                <p>Errore generico: {response.status}</p>
              </div>
            );
          }
          console.error('Login failed! Status code: ' + response.status);
        }
      } else {
        LoginToken = null;
        this.setState({
          token: null,
          badInput: false,
          loggedIn: false
        });
        Login.popError(
          <div>
            <b>LOGIN FALLITO!</b><br/>
            <p>Richiesta invalida.</p>
          </div>
        );
        console.error('Login failed! Bad request\n' + response.message);
      }
    });
  }

  handlePasswordReset(e) {
    e.preventDefault();
    api.forgot_password(this.state.username).then(res => {
      switch (res.status) {
        case Status.NO_CONTENT:
          Login.popSuccess(
            <div>
              <b>MAIL INVIATA!</b>
              <p>Abbiamo inviato una e-mail all'indirizzo associato al tuo username.</p><br/>
              <p>Segui le sue istruzioni per recuperare il tuo account.</p>
            </div>
          );
          break;
        case Status.UNAUTHORIZED:
          Login.popError(
            <div>
              <b>INVIO FALLITO!</b>
              <p>Nessun indirizzo e-mail associato all'account specificato.</p><br/>
              <p>Assicurati di aver inserito dati giusti e riprova.</p>
            </div>
          );
          break;
        case Status.BAD_REQUEST:
          Login.popError(
            <div>
              <b>INVIO FALLITO!</b>
              <p>Errore:</p>
              <p>{res.error}</p>
            </div>
          );
          break;
        default:
          Login.popError(
            <div>
              <b>INVIO FALLITO!</b>
              <p>{res.status} - {res.error}</p>
            </div>
          );
      }
    });
  }

  render() {
    if (current_username === "") {
      current_username = localStorage.getItem('username');
    }
    const login_banner =
      <div className="testo-login">
        {
          config.login_banner.map((str, index) => {
            return (
              <React.Fragment>
                {str}
                {
                  index !== config.welcome.length - 1 &&
                    <React.Fragment>,<br/></React.Fragment>
                }
              </React.Fragment>
            )
          })
        }
      </div>;
    return (
      <AppWrapper>
        <div className="text-center">
          {
            !this.state.wantsReset &&
              <React.Fragment>
                {login_banner}
              </React.Fragment>
          }
        </div>
        <div className="margintop20">
          {
            !this.state.wantsReset &&
            <React.Fragment>
              <Row className="marginbottom0">
                <Input s={12} onChange={(e, val) => this.setState({username: val})} className="input-login" label="Username o Email" validate><Icon>account_circle</Icon></Input>
                <Input s={12} onChange={(e, val) => this.setState({password: val})} className="input-login" type="password" label="Password"
                       validate><Icon>lock</Icon></Input>
                <Row>
                  <Col s={12}>
                    <div className="text-center">
                      <Button className="actioncolor" id="login-button" onClick={() => this.handleLogin()}>Login</Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col s={12}>
                    <div className="text-center margintop28">
                      <a className="font-size12" href="" id="register-link" onClick={() => this.setState({wantsRegister: true})}>Registrati</a><br/>
                      <a className="font-size12" href="" id="forgot-password-link" onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          wantsReset: true
                        });
                      }}>Password dimenticata?</a>
                      {
                        this.state.loggedIn &&
                        <Redirect to={{ pathname: "/app", search: window.location.search }}/>
                      }
                      {
                        this.state.wantsRegister &&
                        <Redirect to="/signup"/>
                      }
                    </div>
                  </Col>
                </Row>
              </Row>

            </React.Fragment>
          }
          {
            this.state.wantsReset &&
            <React.Fragment>
              <p className="testo-login">Inserisci il nome dell'account con il quale hai fatto l'accesso</p>
              <Row>
                <Input s={12} onChange={(e, val) => this.setState({username: val})} className="input-login" label="Username o Email" validate><Icon>account_circle</Icon></Input>
              </Row>
              <div s={12} className="text-center">
                <Button disabled={this.state.username === ''} className="actioncolor" id="login-button" onClick={(e) => this.handlePasswordReset(e)}>Invia</Button><br/>
              </div>
              <div className="text-center margintop20">
                <a className="font-size12" href="" id="register-link" onClick={(e) => {
                e.preventDefault();
                this.setState({username: '', wantsReset: false});
                }}>Annulla</a>
              </div>
            </React.Fragment>
          }
        </div>
        <KeyHandler keyEventName={KEYUP} keyValue="Enter" onKeyHandle={() => this.handleLogin()}/>
      </AppWrapper>
    );
  }
}

