import React, { Component } from 'react';
import {Icon, Navbar, NavItem, Button, Input} from "react-materialize";
import {LABEL_DID_INIT, Labels} from "../routes/App"
import {Printer} from "./PrintManager";
import {getLoginToken, removeLoginToken} from "../routes/Login";
import {Redirect} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ConfirmLink from "react-confirm-dialog";
import {api} from "../api";
import NewsContainer from "./NewsContainer";
import {DOMAIN_REMOTE} from "../index";
import {config} from "../config/config"

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.onPrint = this.onPrint.bind(this);
    this.state = {
      wantsSave: false,
      wantsProfile: false,
      saveName: '',
      wantsLogout: false
    };
    this.closeSave = this.closeSave.bind(this);
    this.logout = this.logout.bind(this);
  }

  logout() {
    localStorage.removeItem('username');
    removeLoginToken();
    this.setState({
      wantsLogout: true
    });
  }

  onPrint(e) {
    e.preventDefault(); // ALWAYS do this otherwise the page WILL reload!
    if (!LABEL_DID_INIT) return;
    let printRender = this.props.printRender;
    let printer = this.props.printer;

    printRender.setState({
      displayPrintOverlay: true
    }, () => {
      let merged = Printer.resolveMerge(Labels, printRender);
      if (!merged.html) {
        toast.error(
          <div>
            <b>Errore di stampa:</b>
            <p>{merged.error}</p>
          </div>
        );
      } else {
        printer.printDocument(merged.html).then(() => {
          printRender.setState({
            printHtml: merged.html,
            visibleFinalPrint: true
          });
          api.increment_print_count(getLoginToken(), printRender.state.model.identifier);
        });
      }
      printRender.resetMergeState();
    });
    this.props.labelBuilder.handleSidebarClose();
  }

  closeSave() {
    this.setState({
      wantsSave: false
    });
  }

  render() {
    return (
      <div>
        <div>
          <Navbar className="no-print" href="" brand={
            config.show_app_logo && (
              <img className="logo" style={{maxHeight: 110, maxWidth: "50%"}} src={DOMAIN_REMOTE + config.app_logo} alt="AppTac"/>
            )
          } right>
            {
              config.show_news_banner && (
                <NavItem onClick={(e) => e.preventDefault()}>
                  <NewsContainer velocity={0.06} maxNews={2}/>
                </NavItem>
              )
            }
            {
              config.enable_login && (
                <NavItem data-tip="Il mio profilo" data-for="global-tooltip" onClick={(e) => {
                  e.preventDefault();
                  this.setState({wantsProfile: true})
                }} href="">{this.props.username}</NavItem>
              )
            }
            {
              config.enable_new_label && (
                <NavItem data-tip="Nuova Etichetta" data-for="global-tooltip" href=""><Icon>note_add</Icon></NavItem>
              )
            }
            {
              config.enable_login && (
                <>
                  <NavItem data-tip="Salva" data-for="global-tooltip" href="" onClick={(e) => {
                    e.preventDefault();
                    if (!LABEL_DID_INIT) return;
                    this.setState({ wantsSave: !this.state.wantsSave }, () => {
                      let icon = document.getElementsByClassName('save-close-icon')[0];
                      if (icon) icon.onclick = this.closeSave;
                    });
                  }}>
                    <Icon>save</Icon>
                  </NavItem>
                  <NavItem data-tip="Carica" data-for="global-tooltip" href="" onClick={() => localStorage.setItem('wantsToLoad', true)}>
                    <Icon>cloud_upload</Icon>
                  </NavItem>
                </>
              )
            }
            {
              config.enable_extended_print_button ? (
                <NavItem onClick={(e) => this.onPrint(e)}>
                  <Icon left>print</Icon> <b>Stampa etichetta</b>
                </NavItem>
              ) : (
                <NavItem data-tip="Stampa" data-for="global-tooltip" onClick={(e) => this.onPrint(e)}>
                  <Icon>print</Icon>
                </NavItem>
              )
            }
            {
              config.enable_login && (
                <ConfirmLink action={this.logout} confirmMessage="Sei sicuro di voler effettuare il logout?"
                             confirmText="Sì" cancelText="No">
                  <NavItem data-tip="Logout" data-for="global-tooltip" href="" onClick={(e) => {
                    e.preventDefault();
                  }}>
                    <Icon>exit_to_app</Icon>
                  </NavItem>
                </ConfirmLink>
              )
            }
          </Navbar>
        </div>
        <div>
          {
            this.state.wantsSave &&
              <div className="save-box">
                <label>Inserisci il nome dell'etichetta</label>
                <Icon className="save-close-icon">close</Icon>
                <Input className="save-input" defaultValue={this.state.saveName} onChange={(e, val) => {
                  this.setState({
                    saveName: val
                  });
                }}/>
                <Button disabled={this.state.saveName === ''} onClick={() => {
                  this.props.onSave(this.state.saveName);
                  this.setState({wantsSave: false});
                }} waves="light" className="actioncolor">Salva</Button>
              </div>
          }
          {
            this.state.wantsProfile &&
              <Redirect to="/profile"/>
          }
          {
            this.state.wantsLogout &&
              <Redirect to="/login"/>
          }
        </div>
        <ToastContainer autoClose={3000}/>
      </div>
    );
  }
}
