import React from 'react';
import {Kitchen} from "../FabricCanvas"
import {BaseEditor} from "./BaseEditor";
import {Button, Icon} from "react-materialize";
import ReactUploadFile from "react-upload-file";
import {DOMAIN_REMOTE, REST_POINT} from "../../index";
import {toast} from "react-toastify";
import {api, Status} from "../../api";
import ReactTooltip from "react-tooltip";
import {config} from "../../config/config";

export class ImageEditor extends BaseEditor {
	constructor(props) {
		super(props, ['image']);
		this.storedImages = [];
		this.state.images = []; // Array of image urls to use
		this.state.uploadProgress = 0; // Used for progress bar
		this.state.currentPage = 1;
		this.dragging = null;
		this.renderNewImageButton = this.renderNewImageButton.bind(this);
		this.getImages = this.getImages.bind(this);
		this.createImage = this.createImage.bind(this);
		this.promptFile = this.promptFile.bind(this);
		this.options = {
			baseUrl: REST_POINT + '/image',
			timeout: 10000,
			fileFieldName: 'file',
			dataType: 'json',
			accept: 'image/*',
			multiple: false,
			requestHeaders: {
				Authorization: localStorage.getItem('loginToken')
			},
			beforeUpload: (files) => {
				if (typeof files === 'string') return true;
				return files[0].size < 1024 * 1024 * 20;
			},
			uploading: (progress) => {
				this.setState({
					uploadProgress: progress.loaded / progress.total
				});
			},
			uploadSuccess: (res) => {
				toast.success(
					<div>
						<b>UPLOAD COMPLETATO!</b>
						<p>Immagine aggiunta con successo</p>
					</div>
				);
				this.setState({
					images: [...this.state.images, {
						path: JSON.parse(res).message
					}]
				});
			},
			uploadError: (err) => {
				toast.error(
					<div>
						<b>UPLOAD FALLITO!</b>
						<p>{err.type} - {err.message}</p>
					</div>
				);
			}
		};
		this.registerDragListeners = this.registerDragListeners.bind(this);
	}

	getImages() {
		if (!config.enable_login) {
			// Do not even attempt to get images, login is disabled
			// Instead, get them from local storage

			// TODO: Do not save BLOB url, save something else
			// this.storedImages = !!localStorage.getItem("images") ? JSON.parse(localStorage.getItem("images")) : [];
			// this.setState({
			// 	images: this.storedImages,
			// });
			return;
		}

		// Get images from "cloud storage"
		api.get_images(localStorage.getItem('loginToken'), this.state.currentPage, 10)
			.then(res => {
				if (res.status === Status.OK) {
					this.setState({
						images: res.images
					});
				} else {
					toast.error(
						<div>
							<b>ERRORE NEL RECUPERO IMMAGINI</b>
							<p>Non è stato possibile recuperare le immagini</p>
						</div>
					);
				}
			});
	}

	createImage() {
		if (this.dragging) Kitchen.Image().add(this.dragging.src);
	}

	registerDragListeners() {
		let _this = this;
		let images = Array.from(document.querySelectorAll('.image-preview'));
		images.forEach(img => {
			img.addEventListener('dragstart', () => {
				_this.dragging = img;
			}, false);
			img.addEventListener('dragend', () => {
				_this.dragging = null;
			}, false);
		});
	}

	onComponentMount() {
		this.getImages();
		Kitchen.getCanvas().on({
			'drop': () => {
				this.createImage();
			}
		});
	}

	componentDidUpdate() {
		this.registerDragListeners();
	}

	onDelete(deleted) {
		Kitchen.Image().remove(deleted.get('id'));
	}

	async promptFile() {
		const input = document.createElement("input");
		input.type = "file";
		input.multiple = false;
		input.accept = "image/jpeg,image/png";
		const file = await new Promise((resolve) => {
			document.activeElement.onfocus = function() {
				document.activeElement.onfocus = null;
				setTimeout(resolve, 500);
			};
			input.onchange = function() {
				var files = Array.from(input.files);
				resolve(files[0]);
			};
			input.click();
		});
		if (!file) {
			return;
		}

		const src = URL.createObjectURL(file);

		this.setState({
			images: [...this.storedImages, { src }]
		});
		localStorage.setItem("images", JSON.stringify([...this.storedImages, { src }]));
	}

	renderNewImageButton() {
		if (!config.enable_login) {
			return (
				<>
					<Button data-tip="Nuova immagine (PNG o JPEG supportati)" data-for="upload-tooltip"
							className='defaultcolor editor-button add-button'
							waves='light' icon='add' onClick={this.promptFile}/>
				</>
			)
		}

		return (
			<React.Fragment>
				<ReactUploadFile options={this.options} chooseFileButton={
					<Button data-tip="Nuova immagine (PNG o JPEG supportati)" data-for="upload-tooltip"
					        className='defaultcolor editor-button add-button'
					        waves='light' icon='add'/>
				}
				/>
				<ReactTooltip id="upload-tooltip"/>
			</React.Fragment>
		);
	}

	onRender() {
		// let editingIds = [];
		// this.state.editingObjects.forEach((obj) => { editingIds.push(obj.get('id'));  });
		return (
			<div className={this.props.className + (this.props.currentEditorType === this.props.type ? " show" : " hide")}>
				<p className="header-sidebar"><Icon className="icon-sidebar">image</Icon> IMMAGINE</p>
				{super.renderCloseButton()}
				{super.listenForDelete()}
				{super.listenForDeselect()}
				<div className="default-editor bordable-default">
					{this.renderNewImageButton()}
					{super.renderRemoveButton()}
				</div>
				<div id="image-controls">
					{
						this.state.images.length > 0 &&
						<section style={{
							marginTop: 15
						}}>
							<div>
								{
									this.state.images.map((image, index) => {
										return (
											<div key={index} style={{
												float: 'left',
												padding: 5
											}}>
												<img alt="" draggable className="image-preview" src={!!image.path ? (DOMAIN_REMOTE + '/' + image.path) : image.src}/>
											</div>
										);
									})
								}
							</div>
						</section>
					}
				</div>
			</div>
		);
	}
}